<template>
 <v-container
    id="rewards"
    fluid
    tag="section"
    v-if="shelve" 
  >
      <shelf-component
      v-for="(shelf, i) in shelve.shelfs"
      :key="`shelf-${i}`"
      :shelfIndex="i"
      />
    
 </v-container>

</template>

<script>

// import { mapState } from 'vuex'
// import { CurrencyFilter } from '@/utils/filters'
import ShelfComponent from '@/components/ShelfComponent'

export default {
  computed: {
    // ...mapState({
    //   warehouse: state => state.warehouse,
    // }),
  },
    components: {
      ShelfComponent,
    },
  props: {
    shelve: {
      type: Object,
      // default: () => ({
      // }),
    },
  },
  data() {
    return {
      loading: false,
      search: '',
    }
  },
  methods: {
    changeShelf(){
      // this.$store.commit('warehouse/CHANGE_SHELF', {shelf: t, index: i})
      // this.$emit('change', this.shelve)
    }
  },
  mounted(){
    // console.log(this.$store.state.route)
  },

  
}
</script>