<template>
      
      <ShelveComponent 
        :shelve="warehouse.shelves[Number($route.params.shelve_index) - 1]"
        @change="changeHappened"
      />
    

</template>

<script>

import { mapState } from 'vuex'
// import { CurrencyFilter } from '@/utils/filters'
import ShelveComponent from '@/components/ShelveComponent'

export default {
  computed: {
    ...mapState({
      warehouse: state => state.warehouse,
    }),
  },
    components: {
      ShelveComponent,
    },
  data() {
    return {
    }
  },
  methods: {
    changeHappened(t){
      this.$store.commit('warehouse/CHANGE_SHELF', t)
    }
  },
  beforeMount(){
    this.$store.commit('warehouse/SET_CURRENT_SHELVE', Number(this.$route.params.shelve_index) - 1)
  },
  mounted(){
    
  },

  
}
</script>